import getAPIKey from './getAPIKey'

let subscriptionKey = getAPIKey();

function getBingImageSearchResult(searchTerm) {
    return fetch('https://mike-shecket.cognitiveservices.azure.com/bing/v7.0/images/search?q=' + encodeURIComponent(searchTerm),
    {
      headers : {
        'Ocp-Apim-Subscription-Key' : subscriptionKey
        }
    })
    .then(function(response) {
        let responseJSON = response.json()
        return responseJSON
    })
    .then(function(data) {
      return data.value[0].contentUrl
    })



};


export default getBingImageSearchResult
