// Import the mighty, mighty React framework!
import React from "react";

// This will be the main child component of
// this collection.
import Card from "./Card"

function CardCollection() {
  return (
    // Right now, we just have three cards and
    // they're hard-coded as people with
    // specific IDs. I'll probably make this
    // fancier in the future.
    <div className="cardCollection">
      <Card type="people" id="1"/>
      <Card type="people" id="2"/>
      <Card type="people" id="3"/>
    </div>
  );
  }

export default CardCollection
