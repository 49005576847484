// Import the lovely and beautiful React framework. <3
import React from 'react';
import ReactDOM from 'react-dom';

// Import our main app component
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// I don't know what this is, but it's in create-react-app, so...
// whatever, I'll leave it here, I guess.
serviceWorker.unregister();
