// Import the handsome and strong React framework!
import React from "react";

// This will be the main child component.
import CardCollection from "./components/CardCollection"

import "./styles.css";

function App() {
  return (
    // Right now, all that's in here is the card
    // collection. I guess at some point some menus
    // or something might live here as well.
    <div className="App">
      <CardCollection />
    </div>
  );
}

export default App
