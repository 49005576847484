// Import the React framework in all its majesty!
import React, {useState, useEffect} from "react"

// Import the function that makes the API call from
// its own file.
import getBingImageSearchResult from "./getBingImageSearchResult"

function Card(props) {

  // Set up all our state hooks. A lot of them are flags
  // so that we know when we've already loaded some info
  // to make sure we don't make unnecessary repeated API
  // calls.
  const [cardData, setCardData] = useState({})
  const [cardDataLoaded, setCardDataLoaded] = useState(false)
  const [name, setName] = useState("Unknown")
  const [nameLoaded, setNameLoaded] = useState(false)
  const [homeworld, setHomeworld] = useState("Unknown")
  const [homeworldLoaded, setHomeworldLoaded] = useState(false)
  const [species, setSpecies] = useState("Unknown")
  const [speciesLoaded, setSpeciesLoaded] = useState(false)
  const [image, setImage] = useState("")
  const [imageLoading, setImageLoading] = useState(false)
  const [imageLoaded, setImageLoaded] = useState(false)


  useEffect(() => {
    // First, get the full data on this character/object using its type and id,
    // and specifically get the character name and store it in state.
    
    // Make sure we didn't already load the data for this card.
    if (!cardDataLoaded) {

    // Make a call to Swapi to get the data object for the person/thing
    // that will be on this card.
    fetch("https://swapi.co/api/"+props.type+"/"+props.id+"/")
        .then(function(response) {
            let responseJSON = response.json()
            return responseJSON
        })
        .then(function(data) {
          setCardData(data)
          setCardDataLoaded(true)

        })
      }

      // This is probably excessively careful, but we make sure
      // that we loaded the card data before we set the
      // name of the character/thing for this card.
      if (!nameLoaded && cardDataLoaded) {
        setName(cardData.name)
        setNameLoaded(true)
      }

      if (!imageLoading && !imageLoaded && nameLoaded) {
      // Now, using the name we got from Swapi, get the first Bing Image Search
      // result for that name and use it as the card image.

      // Set a flag to show we're in the process of loading the image.
      // We could add a swirly loading thingy in place of the image if
      // we want to later.
      setImageLoading(true)

      // Make the API call and get a Promise object that hopefully
      // will resolve to the URL of the first image search result.
      getBingImageSearchResult(cardData.name).then ((characterImage) => {

        // Use the state hook to set the characterImage variable...
        setImage(characterImage)
        // ...and set the flag that says we've now loaded the image.
        setImageLoaded(true)
      })


      }


    if (!homeworldLoaded & nameLoaded) {
    // Using the URL for the homeworld as provided in the character's data object,
    // get the name of the homeworld and store it in state.
    fetch(cardData.homeworld)
        .then(function(response) {
            let responseJSON = response.json()
            return responseJSON
        })
        .then(function(data) {
          setHomeworld(data.name)
          // Set a flag that shows that we now have this info.
          setHomeworldLoaded(true)
        })
    }
       
    if (!speciesLoaded && nameLoaded && homeworldLoaded) {
      // Finally, using the URL for species provided by the API, get the name of
      // the character's species and store it in state.
      fetch(cardData.species)
          .then(function(response) {
              let responseJSON = response.json()
              return responseJSON
          })
          .then(function(data) {
            setSpecies(data.name)
            // Same deal--set this flag because now we know the species.
            setSpeciesLoaded(true)
          })
 

      }

    // Here are all of the changing things that useEffect listens for. I'm not
    // sure if we need more or fewer or what, but things seem to be working
    // with this many.
    },[cardData, homeworld, species, name, image, nameLoaded, homeworldLoaded])

  // We pick a color to display the homeworld based on the ID number
  // that was passed to us in props. If this card isn't displaying a
  // person then this won't work right, but for now people is all we're
  // showing on these cards.
  let homeworldColor = "hsl("+Math.round(props.id/87 * 15000 % 255)+",100%,50%)"

  // We do the same for the border color.
  let borderColor = "hsl("+Math.round(props.id/87 * 7500 % 255)+",100%,50%)"

  // Render the components of the card in the return statement.
  // The stylesheet displays everything in special, cool ways.
  return (
    // The card image is the background image of the card
    <div className="card" style={{backgroundImage: "url(" + image + ")", borderColor: borderColor}}>
      <div className="topBorder"></div>
      <p className="name">{name}</p>
      
      {/* The "0" after the homeworld makes a swash in the Brannboll font. */}
      <p className="homeworld" style={{color: homeworldColor}}>{homeworld+"0"}</p>

      {/* The small Star Wars logo is displayed just behind the species name.*/}
      <p className="species">{species}</p><div className="logo"><img src="Starwars.png" alt="Star Wars"/></div>
      <div className="bottomBox"></div>   
    </div>
  );
}

// Make sure to export!
export default Card